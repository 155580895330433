import { 
    BlockJudges, 
    BlockHeading, 
    BlockTextImage,
    BlockSingleImage,
    BlockGrid,
    BlockDoubleImage
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'judges': BlockJudges,
        'heading': BlockHeading,
        'text_with_image': BlockTextImage,
        'grid': BlockGrid,
        'single_image': BlockSingleImage,
        'full_width_image': BlockSingleImage,
        'double_portrait_images': BlockDoubleImage
    }
    
    return blocks[layout]
}
