import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { ribbon, yellow } from '../../../styles/colors';
import Grid, { GridItem, Image } from '../../Grid/Grid';

class BlockJudges extends React.Component {

	state = {
	
	}

	renderItems = () => {
		const { content } = this.props;

		return content.map((item, i) => {
			return (
				<GridItem
					key={i}
				>
					<Image
						image={item.image}
						ratio={i % 2 ? 'landscape' : 'portrait'} 
					/>
                    {item.category && (
						<Category>{item.category}</Category>
					)}
				</GridItem>
			)
		})
	}
	
	render() {		        
		return (
            <Grid
				items={this.renderItems()}
			/>
		)
	}
}


const Text = styled.div`
	
`



BlockJudges.wrapper = css`

`

export default BlockJudges
