import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { grey, brown } from './colors';

const maxWidth = 1330;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: ${grey};
    }
    
    a {
        text-decoration: none;
    }

    a, p, div {
        color: ${brown};
        word-break: normal;
        hyphens: none;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
	}
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 32px; 
    padding-right: 32px;
    box-sizing: border-box;

    ${media.phone`
        padding-left: 24px; 
        padding-right: 24px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const videoEmbed = css`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`