import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet';

import { Footer } from '../../components';

import '../../styles/global'
import '../../styles/fonts'
import { GlobalStyles } from '../../styles/global';
import { Fonts } from '../../styles/fonts';
import { resolveTheme } from '../../styles/themes';


const Layout = ({children, theme, footerImage}) => (
  	<StaticQuery
    	query={graphql`
      		query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
        		}

				# Category Themes

				allWordpressCategories {
					edges {
						node {
							slug	
							acf_json
						}
					}
				}
				
                wordpressGlobal {
                    acf_json
                }
      		}
    	`}
    	render={data => (
      		<>
			  	<GlobalStyles/>
				<Fonts/>

				<Helmet>
					<link rel="stylesheet" href="https://use.typekit.net/rmt8tsa.css"/>
				</Helmet>
				
				<ThemeProvider
					theme={resolveTheme(data.allWordpressCategories.edges, theme)}
				>
					<>
						{children}	
						<Footer 
							data={data} 
							footerImage={footerImage}
						/>
					</>
				</ThemeProvider>                
      		</>
    	)}
  	/>
)

export default Layout
