import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { ribbon } from '../../../styles/colors';
import { heading } from '../../../styles/type';

class BlockHeading extends React.Component {

	state = {
	
	}
	
	render() {
		const { text, colour, caption } = this.props;
        
		return (
            <>
				{text && (
					<Heading
						colour={colour}
						dangerouslySetInnerHTML={{__html: text}}  
					/>
				)}

				{caption !== '' && (
					<Caption
						colour={colour}
						dangerouslySetInnerHTML={{__html: caption}}  
					/>
				)}
			</>
		)
	}
}


const Heading = styled.div`
	max-width: 100%;
	text-align: center;
	${heading};
	color: ${props => props.colour};
	margin-bottom: 0;
	text-transform: uppercase;
`

const Caption = styled.div`
	max-width: 100%;
	text-align: center;
	color: ${props => props.colour};

	font-family: 'Playfair Regular';
	font-size: 34px;
	margin-top: 15px;

	${media.phone`
		font-size: 21px;
		margin-top: 10px;
	`}
`

BlockHeading.wrapper = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 480px;
	padding-bottom: 0;

	${media.phone`
		height: 200px;
        padding-bottom: 80px!important;
    `}
`

export default BlockHeading
