import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { ribbon, grey, burntRed, brown } from '../../../styles/colors';
import { paragraph, strongParagraph, blockHeading } from '../../../styles/type';

class BlockTextImage extends React.Component {

	state = {
	
	}
	
	render() {
        const { heading, use_image, text, image_type, image, caption, sponsor_url, font_choice } = this.props;
        		        
		return (
            <>
				{heading && (
					<Heading>{heading}</Heading>
				)}
				
				<Content>
					<Text
						dangerouslySetInnerHTML={{__html: text}} 
                        useImage={use_image} 
                        font_choice={font_choice}
					/>
				
					{use_image && (
						<ImageWrapper
							type={image_type}
						>
							<Image
								image={image.sizes.large}
								type={image_type}
								href={sponsor_url !== '' && sponsor_url}
								target={'_blank'}
							/>

							{caption && image_type == 'logo' && (
								<Caption>
									{caption}
								</Caption>
							)} 

						</ImageWrapper>
					)}
					
				</Content>
			</>
		)
	}
}

const Heading = styled.div`
	${blockHeading}
`

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 50px;

	${media.phone`
		padding-top: 16px;
		flex-direction: column;
	`}
`

const Text = styled.div`
	max-width: 100%;
	box-sizing: content-box;
	flex: 0 1 calc(50% - 10px);
	hyphens: auto;
    word-break: break-all;
	
	> * {
		${paragraph};
		${strongParagraph};

		&:last-child {
			margin-bottom: 0;
		}
	}

	/* Two Column */
			
	${props => {
		if (!props.useImage) return css`
			flex: 1 0 100%;
			columns: 2;
			column-gap: 25px;

			${media.phone`
				columns: 1;
			`}
		`
	}}

    ${props => {
        if (props.font_choice=='helvetica') return css`
			p {
                font-family: 'Helvetica', Arial, sans-serif;
            }
		`
		if (props.font_choice=='helvetica-bold') return css`
			p {
                font-family: 'Helvetica', Arial, sans-serif;
                font-weight: 600;
            }
		`
	}}
`

const ImageWrapper = styled.div`
	flex: 0 1 calc(50% - 10px);
	height: 100%;

	${media.phone`
		flex: 0 1 auto;
		margin-top: 40px;
	`}
		
	${props => {
		if (props.type == 'logo') return css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: auto;
		`
	}}
`

const Image = styled.a`
	${bgImage}
	background-image: url(${props => props.image});
	display: flex;
	
	${props => {
		if (props.type == 'image') return css`
			height: 615px;

			${media.phone`
				height: 460px;
			`}
		`

		if (props.type == 'logo') return css`
			width: 180px;
			height: 70px;
			${bgIcon}
		`
	}}
`

const Caption = styled.div`
	${paragraph}
	font-family: 'Playfair Italic';
	max-width: 245px;
	text-align: center;
	margin-top: 24px;
`

BlockTextImage.wrapper = css`
	display: flex;
	flex-direction: column;

	* {
		color: ${brown};
	}
`

export default BlockTextImage
