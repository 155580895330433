import React from 'react'
import { navigate, Link, StaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import moment from 'moment'

import info from '../../assets/images/header/header-left.png'
import infoMobile from '../../assets/images/header/header-left-mobile.png'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { yellow } from '../../styles/colors';

class Header extends React.Component {

    // let diffTime = inputTime - Date.now();
    // let duration = moment.duration(diffTime, 'milliseconds');
    // setCountdown( duration.days() + " Days " + duration.hours() + " Hours " + duration.minutes() + " Mins " + duration.seconds() + " Sec " )

	state = {
		pageLoaded: false
	}

	renderNav = () => {
		const navItems = [
			{
				label: 'Home',
                link: '/',
                linkType: 'internal'
			},
            // {
            //     label: 'Finalists',
            //     link: '/finalists',
            //     linkType: 'internal'
            // },     
            {
                label: 'Judges',
                link: '/judges',
                linkType: 'internal'
			},
			{
				label: 'About',
                link: '/#about',
                linkType: 'internal'
            },
            // {
            //     label: 'Apply',
            //     link: '/apply',
            //     linkType: 'internal'
			// },
            // {
			// 	label: 'Contact',
            //     link: 'mailto:awards@thedesignfiles.net',
            //     linkType: 'external'
			// },
			
            {
                label: `Winners`,
                link: '/winners',
                linkType: 'internal'
            },
   
		]

		const items = navItems.map((item, i) => {
            
            if(item.linkType=='internal'){
                return (
                    <NavItem  
                        to={item.link}
                        activeClassName={'active'}
                        key={i}
                    >
                        {item.label}
                        {i < navItems.length - 1 && '\u00A0/\u00A0'}
                    </NavItem>
                )
            }
            if(item.linkType=='external'){
                return (
                    <NavItemExternal
                        href={item.link}
                        activeClassName={'active'}
                        key={i}
                    >
                        {item.label}
                        {i < navItems.length - 1 && '\u00A0/\u00A0'}
                    </NavItemExternal>
                )
            }

		})

		return <Nav>{items}</Nav>
	}

	render() {    
        const { showNav, showSubmit, theme, sticky, fixed } = this.props; 
		
		
		return (
            <StaticQuery
                query={globalQuery}
                render={data => {    
                    const globalData = JSON.parse(data.wordpressGlobal.acf_json)

                    return (
                        <ThemeProvider
                            theme={theme}
                        >
                            <Wrapper
								sticky={sticky}
								fixed={fixed}
							>
								<Container>

									{showNav ? this.renderNav() : <Info/>}

									<Entries
										dangerouslySetInnerHTML={{__html: globalData.header_text_1}}  
									/> 

									{/* {showSubmit ? 
										<Submit>
											Submit your<br/>
											Project <Link to="/apply">Here</Link>
										</Submit>
										:
										<Awards>
											Entries Close<br/>
                                            15th May 
										</Awards>
									}  */}

								</Container>
							</Wrapper>
                        </ThemeProvider>
                    )
                }}
            />
		)
	}
}

const Info = styled.div`
	background-image: url(${info});
	width: 310px;
	height: 61px; 
	${bgImage}

	${media.phone`
		background-image: url(${infoMobile});
		width: 220px;
		height: 45px; 
		${bgIcon};
		background-position: center left;
	`}
`

const Entries = styled.div`
	font-size: 19px;
	font-weight: bold;
	line-height: 22px;
	letter-spacing: 0.005em;
	text-transform: uppercase;
	color: ${props => props.theme.colour};
	text-align: right;

	margin-left: auto;
	position: relative;

	&::before {
		/* content: '';
		position: absolute;
		left: -7px;
		bottom: 0;
		width: 29px;
		height: 3px;
		background: ${props => props.theme.colour}; */
	}

	${media.phone`
		font-size: 11px;
		line-height: 12px;
		letter-spacing: 0;

		&::before {
			display: none;
		}
	`}
`

const Awards = styled.div`
	font-size: 19px;
	line-height: 22px;
	letter-spacing: 0.005em;
	text-transform: uppercase;
	color: ${props => props.theme.colour};
	text-align: right;
	text-decoration: underline;
	margin-left: 85px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0px;
		bottom: 2px;
		width: 65px;
		height: 1px;
		background: ${props => props.theme.colour};
	}

	&::after {
		content: '•';
		position: absolute;
		left: 0px;
		bottom: 0px;
	}


	${media.phone`
		display: none;
	`}
`


const Submit = styled.div`
	font-size: 19px;
	line-height: 22px;
	letter-spacing: 0.013em;
	text-transform: uppercase;
	color: ${props => props.theme.colour};
	text-align: right;
	margin-left: 85px;

	> * {
		font-style: italic;
		font-weight: bold;
		position: relative;
		color: ${props => props.theme.colour};

		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 2px;
			height: 1px;
			background: ${props => props.theme.colour};
		}
	}

	${media.phone`
		display: none;
	`}
`

// Nav

const Nav = styled.div`
	display: flex;
`

const navlink = css`
    font-size: 20px;
	letter-spacing: 0.027em;
	text-transform: uppercase;
	position: relative;
	color: ${props => props.theme.colour};
	
	&.active {
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 2px;
			right: 18px;
			height: 1px;
			background: ${props => props.theme.colour};

			${media.phone`
				bottom: 6px;
				right: 11px;
			`}
		}

		&:last-child {
			&::before {
				right: 0;
			}
		}
	}

	${media.phone`
		font-size: 13px;
		line-height: 27px;
	`}
`

const NavItem = styled(Link)`
	${navlink}
`
const NavItemExternal = styled.a`
	${navlink}
`

const Container = styled.div`
	${container}
	${padding}
	align-items: flex-start;
	height: auto;



	
`

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 10;
	padding-top: 56px;
	background: ${props => props.theme.background};

	${media.phone`
		padding-top: 35px;
	`}

	${props => {
		if (props.sticky) return css`
			position: sticky;
			top: 0;
			padding-bottom: 32px;
			z-index: 20;

			${media.phone`
				padding-top: 10px;
				padding-bottom: 10px;

				${Entries} {
					display: none;
				}
			`}
		`
	}}

	${props => {
		if (props.fixed) return css`
			position: fixed;
			padding-bottom: 32px;
			top: 0;
			z-index: 20;

			${media.phone`
				padding-top: 10px;
				padding-bottom: 10px;

				${Entries} {
					display: none;
				}
			`}
		`
	}}
`


export default Header

const globalQuery = graphql`
    query {
        wordpressGlobal {
            acf_json	
        }	
    }
`
