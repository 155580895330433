import { find } from 'lodash';

export const resolveTheme = (data, slug) => {
    const match = find(data, {node: {slug : slug}});
    const cat = JSON.parse(match.node.acf_json);

    return {
        text: cat.text_colour,
        background: cat.background_colour,
    }
}
