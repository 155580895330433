import React from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach } from 'lodash'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { yellow } from '../../../styles/colors';
import { paragraph, blockHeading } from '../../../styles/type';

import arrowDown from '../../../assets/images/arrow-down.svg'

class BlockGrid extends React.Component {

	state = {
		expanderActive: false
	}

	getCategoryString = (terms) => {
		let string = '';

		forEach(terms, (item, i) => {
			string = `${string}${i > 0 ? '/' : ''} ${item.name} `
		})
		
		return string
    }
    

    expandItems = () => {
        this.setState({
            expanderActive: true
        })
    }

    resolveImageRatio = (image) => {
        let ratio = 'portrait'
        if(image.sizes[`medium2-height`] < image.sizes[`medium2-width`]){
            ratio = 'landscape'
        }
        return ratio
    }
	
	resolveItemData = (data) => {
		const post = data.post[0] || false;
        const type = data.post_type;

		// Resolve fields from linked post

		let postFields = {}

		if (post) {
			postFields = {
				title: post.post_title,
                image: type == 'judges' ? data.post_acf.image : data.post_acf.hero_image,
				description: type == 'judges' ? data.post_acf.bio : data.post_acf.short_description,
				category: type == 'judges' ? this.getCategoryString(data.specialising) : data.post_acf.category && data.post_acf.category.post_title
            }
        }
 
        let title = postFields.title
        let short_description = postFields.description
        let image
        let image_ratio
        if(postFields.image){
            image = postFields.image.sizes.medium2
            image_ratio = this.resolveImageRatio(postFields.image)
        } 
        let category = postFields.category

        if(data.override_fields){
            if(data.title !== '') title = data.title 
            if(data.short_description !== '') short_description = data.short_description 
            if(data.image !== false){
                image = data.image.sizes.medium2
                image_ratio = this.resolveImageRatio(data.image)
            } 
            if(data.category !== '') category = data.category 
        }

		// Override 

		// return {
		// 	...data,
		// 	title: data.title !== '' ? data.title : postFields.title,
		// 	short_description: data.short_description !== '' ? data.short_description : postFields.description,
		// 	image: data.image !== false ? data.image.sizes.medium2 : postFields.image && postFields.image.sizes.medium2, 
		// 	category: data.category !== '' ? data.category : postFields.category
        // }
        
        return {
			...data,
			title: title,
			short_description: short_description,
            image: image,
            image_ratio: image_ratio,
			category: category
        }
	}

	renderItems = (offset = false) => {
        const { items, use_expander, expander_text, hideCategory, items_per_row } = this.props;

		return items.map((data, i) => {
            const item = this.resolveItemData(data);

          //  console.log('item',item);

            if(use_expander && !offset && i >= 9){
                return 
            }
            if(use_expander && offset && i < 9){
                return 
            }

			return (
				<GridItem
                    key={i}
                    items_per_row={items_per_row}
				>	
					<Image
						image={item.image}
						ratio={item.image_ratio}
					/>

					{item.title && (
						<Title>{item.title}</Title>
					)}

					{item.short_description && (
						<Description
                            fullwidth={items_per_row=='two' ? true : false}
							dangerouslySetInnerHTML={{__html: item.short_description}}  
						/>
					)}

					{item.category && !hideCategory && (
						<Category
							dangerouslySetInnerHTML={{__html: item.category}}  
						/>
					)}
				</GridItem>
			)
		})
	}
	
	render() {   
        const { items, display_heading, heading, use_expander, expand_text } = this.props;
        
		return (
			<>
				{display_heading && (
					<Heading>{heading}</Heading>
				)}
				
				<Content>
        
                    {this.renderItems()}
                
				</Content>


                { use_expander && (
                    <ViewAll
                        onClick={() => this.expandItems()}
                        active={!this.state.expanderActive}
                    >
                        <Text>{expand_text}</Text>
                        <Arrow />
                    </ViewAll>            
                )}


                { use_expander && (
                    <ExpandedItems
                        active={this.state.expanderActive}
                    >
                        {this.renderItems(true)}
                    </ExpandedItems>
                )}

			</>
		)
	}
}



const Heading = styled.div`
	${blockHeading}
	margin-bottom: 50px;

	${media.phone`
		margin-bottom: 20px;
	`}
`


const Content = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 30px);
`


// Item

const Title = styled.div`
	font-size: 31.8px;
	line-height: 38.16px;
	font-weight: 600;
	margin-top: 38px;

	${media.phone`
		margin-top: 22px;
	`}

`
const Description = styled.div`
	margin-top: 18px;
	max-width: 360px;

	p {
		font-size: 23px;
		line-height: 26px;  
		letter-spacing: 0.005em;
		font-family: 'Baskerville';
		font-style: italic;
		margin-bottom: 0;
	}

    ${props => {
		if (props.fullwidth) return css`
			max-width: 100%;
        `
    }}

	${media.phone`
		max-width: none;
	`}
`

const Category = styled.div`
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 0.013em;
	font-family: sofia-pro, sans-serif;
	font-weight: 300;	
	margin-top: 24px;
`

export const GridItem = styled.div`
	flex: 0 1 calc(33.3% - 30px);
	margin-right: 30px;	
	margin-bottom: 87px;

    ${props => {
		if (props.items_per_row == 'two') return css`
			flex: 0 1 calc(50% - 30px);
        `
    }}

	${media.phone`
		flex: 0 1 100%;
		margin-bottom: 35px;
	`}
`

export const Image = styled.div`
	width: 100%;
	background: ${yellow};
	background-image: url(${props => props.image});
	${bgImage}
    padding-bottom: 132%; 
    

	${props => {
		if (props.ratio == 'landscape') return css`
			padding-bottom: 83.2%;
		`

		if (props.ratio == 'portrait') return css`
            padding-bottom: 132%;  
		`
	}}

	/* ${media.phone`
		padding-bottom: 132% !important;
	`} */
`


// Expander option


const ExpandedItems = styled.div`
    display: flex;
    height: 0;
    opacity: 0;
    transition: opacity 2s ease;

    ${props => {
		if (props.active) return css`
            height: auto;
            opacity: 1;
		`
	}}

    margin-top: 40px;
    flex-flow: row wrap;
	width: calc(100% + 30px);
`

const Arrow = styled.div`
    background-image: url(${arrowDown});
    ${bgIcon}
    width: 18px;
    height: 9.3px;
`
const Text = styled.div``
const ViewAll = styled.div`

    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;

    ${props => {
		if (!props.active) return css`
            display: none;
		`
	}}

    &:hover{
        ${hoverState};
        cursor: pointer;
    }

    ${Text}{
        font-family: sofia-pro, sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 35px;
        text-align: center;
    }

`




BlockGrid.wrapper = css`
	display: flex;
	justify-content: center;
	max-width: 100vw;
	overflow: hidden;
`

export default BlockGrid
