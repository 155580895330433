import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { ribbon, grey, burntRed, brown } from '../../../styles/colors';
import { paragraph, strongParagraph, blockHeading } from '../../../styles/type';

class BlockSingleImage extends React.Component {

	state = {
	
	}
	
	render() {
        let { full_width, show_info, info, image, acf_fc_layout, constrain_to_container } = this.props;

        let imgsrc = image.sizes.medium2

        if (acf_fc_layout == 'full_width_image'){
            imgsrc = image.sizes.full_width
            full_width = true
            if(constrain_to_container){
                full_width = false
            }
        } 
     
		return (
            <>
				<Content>
                    <ImageWrapper
                        fullWidth={full_width}
                    >
                        <Image
                            image={imgsrc}
                            fullWidth={full_width}
                            showInfo={show_info}
                        />

                        {show_info && (
                            <Info
                                dangerouslySetInnerHTML={{__html: info}}  
                            />
                                
                        )} 

                    </ImageWrapper>	
				</Content>
			</>
		)
	}
}

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 50px;

	${media.phone`
		padding-top: 16px;
		flex-direction: column;
	`}
`

const ImageWrapper = styled.div`
	display: flex;
	height: 100%;
    width: 100%;
    flex-direction: row;

	${media.phone`
		flex: 0 1 auto;
		margin-top: 40px;
	`}

    ${props => {
        if (props.fullWidth) return css`
            width: 100vw;
        `
    }}
    
`

const Image = styled.a`
	${bgImage}
	background-image: url(${props => props.image});
	display: flex;
    width: 100%;
    height: 950px;

    ${media.phone`
        height: 460px;
    `}

    ${props => {
        if (props.showInfo) return css`  
            height: 770px;
        `
    }}
`

const Info = styled.div`
	${paragraph}
	font-family: sofia-pro, sans-serif;
	font-weight: 300;
    font-size: 17px;
    line-height: 20px;
	max-width: 245px;
	text-align: left;
	margin-left: 24px;
    align-self: flex-end;
    strong{
        font-family: sofia-pro, sans-serif;
        font-weight: 600;
    }
`

BlockSingleImage.wrapper = css`
	display: flex;
	flex-direction: column;

	* {
		color: ${brown};
	}
`

export default BlockSingleImage
