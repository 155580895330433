import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from 'styled-components'

// import logo from '../../assets/images/logo.svg'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { yellow } from '../../styles/colors';

class Grid extends React.Component {

	state = {

	}
	
	render() {   
		const { items } = this.props;
     
		return (
			<>
				<Heading>Heading</Heading>
				<Content>
					{items}
				</Content>
			</>
		)
	}
}


const Container = styled.div`
	${container}

	display: flex;
	flex-direction: column;
	width: 100%;
`

const Heading = styled.div`
	font-size: 19px;
	text-transform: uppercase;
	letter-spacing: 0.015em;
	padding-bottom: 20px;
	border-bottom: 1px solid black;
`


const Content = styled.div`
	padding-top: 50px;
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 30px);
`

// Item

export const GridItem = styled.div`
	flex: 0 1 calc(33.3% - 30px);
	margin-right: 30px;	
`


export const Image = styled.div`
	width: 100%;
	height: 300px;
	background: ${yellow};
`

export default Grid
