import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { ribbon } from '../../styles/colors'
import { resolveBlock } from './utils';

class Block extends React.Component {

	state = {
	
	}
	
	render() {
		const { id, layout, acf_fc_layout, sectionIndex, lastSection, lastBlock, media, block_type, display, blockTheme, extraStyles, full_width, constrain_to_container } = this.props;
		const BlockLayout = resolveBlock(layout);

		//console.log(this.props)

		if (!display) return null;

        //console.log(acf_fc_layout)
        
		
		return (
			<Wrapper
				id={id}
				className={layout}
				layout={layout}
				blockType={block_type}
				blockProps={this.props}
				styles={BlockLayout && BlockLayout.wrapper}
				sectionIndex={sectionIndex}
				lastSection={lastSection}
				lastBlock={lastBlock}
				media={media}
				blockTheme={blockTheme}
				extraStyles={extraStyles}
			>
				<Container
					fullWidth={acf_fc_layout == 'full_width_image' && !constrain_to_container}
				>
					{BlockLayout && (
						<BlockLayout {...this.props}/>
					)}
				</Container>
			</Wrapper>
		)
	}
}


const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
	align-self: center;
	justify-content: center;

    ${props => {
        if (props.fullWidth) return css`
            padding: 0 !important;
            max-width: 100%;
        `
    }}
`

const Wrapper = styled.div`
	width: 100%;
	padding-bottom: 96px;

	${media.phone`
		padding-bottom: 48px;
	`}
	
	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}

	${props => {
		if (props.blockTheme) return props.blockTheme
	}}

	${props => {
		if (props.extraStyles) return props.extraStyles
	}}

	/* Controlled z-index */

	position: relative;
	z-index: ${props => props.sectionIndex};
`

export default Block
