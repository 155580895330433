import { css } from 'styled-components';
import { media } from './utils';
import { bronze } from './colors';

export const heading = css`
    font-family: 'Lydian';
    font-size: 83px;

	${media.phone`
        font-size: 44px;
	`}
`

export const blockHeading = css`
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 0.015em;
    padding-bottom: 20px;
    border-bottom: 1px solid black;

	${media.phone`
        font-size: 13px;
        padding-bottom: 12px;
	`}
`

export const paragraph = css`
    font-family: 'Playfair Regular';
    font-size: 23px;
    line-height: 26px;  
    letter-spacing: 0.005em;

    ${media.tablet`
        font-size: 21px;
        line-height: 24px;
    `}  

    ${media.phone`
        font-size: 15px;
        line-height: 20px;
    `}

    em {
        font-family: 'Playfair Italic';

        strong {
            font-family: 'Playfair BoldItalic';
        }
    }

    strong {
        font-family: 'Playfair Bold';
    }
`

export const strongParagraph = css`
    strong {
        font-family: 'Helvetica';
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;  

        ${media.tablet`
            font-size: 21px !important;
            line-height: 25.2px !important;
        `}

        ${media.phone`
            font-size: 15px !important;
            line-height: 20px !important;
        `}
    }
`
