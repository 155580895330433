import React from 'react'
import { navigate, Link } from 'gatsby'
import styled, { withTheme } from 'styled-components'
import { getLuminance } from 'polished'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { brown, yellow } from '../../styles/colors';
import Header from '../Header/Header';

class Footer extends React.Component {

	state = {

    }
    
    componentWillMount = () => {
		this.setState({
			acf: JSON.parse(this.props.data.wordpressGlobal.acf_json),
		})
	}
    
    renderLogos = (items) => {

        //console.log('items',items);
		const logos = items.map((item, i) => {     
			return (
				<Logo
                    href={item.link}
                    target="_blank"
                    image={item.logo.sizes.large}
                    key={i}
                />
			)
        })

        return <LogoRow>{logos}</LogoRow>
    }
    
	render() {       
        const { acf } = this.state;
        const { theme, footerImage } = this.props;

        console.log('footer acf',acf);

		return (
            <Wrapper
                image={footerImage}
            >
                <Header
                    theme={{
                        colour: resolveColour(theme),
                        background: 'transparent'
                    }}
                />

                <Container>    

                    <Apply
                        dangerouslySetInnerHTML={{__html: acf.footer_heading}}  
                    /> 
                      
                    {/* <Apply>
                        {acf.footer_heading}
                        <ApplyLink to={'/apply'}>Apply <span>Here</span></ApplyLink>
                    </Apply> */}

                    {acf.presenting_partners && (
                        <Partners>
                            <Heading>Presenting Partner</Heading>
                            {this.renderLogos(acf.presenting_partners)}
                        </Partners>
                    )}
                    
                    {acf.sponsors && (
                        <Partners>
                            <Heading>Sponsors</Heading>
                            {this.renderLogos(acf.sponsors)}
                        </Partners>
                    )}
                    
                    {acf.supporters && (
                        <Partners>
                            <Heading>Supporters</Heading>
                            {this.renderLogos(acf.supporters)}
                        </Partners>
                    )}

                    <Credits>
                        <Credit
                            href={'https://morebymore.com'}
                            target={'_blank'}
                        >
                            {'Website by MORE'.toUpperCase()}
                        </Credit>
                        <Credit
                            href={'https://www.thedesignfiles.net'}
                            target={'_blank'}
                        >
                            www.thedesignfiles.net
                        </Credit>
                    </Credits>

                </Container>
            </Wrapper>
    )
	}
}

const resolveColour = (theme) => getLuminance(theme.text) > 0.3 ? brown : yellow

const Wrapper = styled.div`
    min-height: 1700px;
    background: ${props => props.theme.text};
    background-image: url(${props => props.image});
    ${bgImage}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.phone`
        min-height: 1330px;
    `}
`

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
    flex: 1;
`

const Apply = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        font-size: 52px;
        text-transform: uppercase;
        font-weight: bold;
        color: ${props => resolveColour(props.theme)};
        text-align: center;
        ${media.phone`
            font-size: 32px;
        `}
    }

    a {
        color: ${props => resolveColour(props.theme)};
        text-decoration: underline;
    }

`

const ApplyLink = styled(Link)`
    font-size: 52px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${props => resolveColour(props.theme)};
    text-align: center;

    ${media.phone`
        font-size: 32px;
    `}
    
    span {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: 6px;
            height: 3px;
            background: ${props => resolveColour(props.theme)};
            ${media.phone`
                bottom: 0px;
            `}
        }
    }
`


const Partners = styled.div`
    margin-bottom: 96px;
`

const Heading = styled.div`
    font-size: 19px;
	text-transform: uppercase;
	letter-spacing: 0.015em;
	padding-bottom: 20px;
	border-bottom: 1px solid ${props => resolveColour(props.theme)};
    color: ${props => resolveColour(props.theme)};
    margin-bottom: 70px;

    ${media.phone`
        margin-bottom: 24px;
    `}
`

const LogoRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`

const Logo = styled.a`
    display: flex;
    flex: 0 1 calc(25% - 50px);
    background-image: url(${props => props.image});
    ${bgIcon};
    height: 100px;
    max-width: 300px;
    color: ${props => resolveColour(props.theme)};
    margin-bottom: 30px;

    &:nth-child(5n),
    &:nth-child(7n),
    &:nth-child(7n) {
        justify-content: center;
    }


    ${media.tablet`
        flex: 0 1 calc(30% - 50px);
        
    `}

    ${media.phone`
        flex: 0 1 calc(40% - 25px);
        height: 40px;
    `}
`

// Credits

const Credits = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    margin-top: 64px;

    ${media.phone`
        margin-top: 0px;
    `}
`

const Credit = styled.a`
    color: ${props => resolveColour(props.theme)};
    font-size: 18px;
    font-weight: bold;

    &[href='https://www.thedesignfiles.net'] {
        text-decoration: underline;
    }
`

export default withTheme(Footer)
