import React from 'react'
import { navigateTo } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Link from 'gatsby-link'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { ribbon, grey, burntRed, brown } from '../../../styles/colors';
import { paragraph, strongParagraph, blockHeading } from '../../../styles/type';

class BlockDoubleImage extends React.Component {

	state = {
	
	}
	
	render() {
        const { image_1, image_2  } = this.props;
        		        
		return (
            <>
				<Content>
                    <ImageWrapper>
                        <Image
                            image={image_1.sizes.medium2}
                        />
                        <Image
                            image={image_2.sizes.medium2}
                        />
                    </ImageWrapper>	
				</Content>
			</>
		)
	}
}

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 50px;

	${media.phone`
		padding-top: 16px;
		flex-direction: column;
	`}
`

const ImageWrapper = styled.div`
	display: flex;
	height: 100%;
    width: 100%;
    flex-direction: row;

	${media.phone`
		flex: 0 1 auto;
		margin-top: 40px;
		flex-direction: column;
	`}
`

const Image = styled.a`
	${bgImage}
	background-image: url(${props => props.image});
	display: flex;
    width: 50%;
    height: 850px;

    ${media.phone`
        height: 460px;
		width: 100%;
		margin-right: 0;
		
		&:not(:last-child) {
			margin-bottom: 30px;	
		}
	`}

    ${props => {
        if (props.showInfo) return css`  
            height: 770px;
        `
    }}

	&:not(:last-child) {
		margin-right: 30px;	
	}
`

BlockDoubleImage.wrapper = css`
	display: flex;
	flex-direction: column;

	* {
		color: ${brown};
	}
`

export default BlockDoubleImage
