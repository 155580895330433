import React, { Component } from 'react'
import Helmet from 'react-helmet'

import ogImage from '../../assets/images/og-image.png';

class MetaTags extends Component {
	
    render() {
        const { title, description, image, url, noPrefix } = this.props;
        const titlePrefix = noPrefix ? '' : 'The Design Files Awards – '  
		
        return (
            <Helmet>
                <meta name="og:image" content={'https://tdfawards.wpengine.com/wp-content/uploads/og-image.png'} />

                {title && (
                    <title>{titlePrefix + title}</title>
                )}
                {title && (
                    <meta name="og:title" content={titlePrefix + title} />
                )}
                {description && (
                    <meta name="description" content={description && description.replace(/<\/?[^>]+(>|$)/g, "")} />
                )}
                {description && (
                   <meta name="og:description" content={description && description.replace(/<\/?[^>]+(>|$)/g, "")} />
                )}
            </Helmet>
        )
    }
}

export default MetaTags 